import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import {
  PieChartOutlined,
  AuditOutlined,
  TeamOutlined,
  UserOutlined,
  SnippetsOutlined,
  BarChartOutlined,
  CalendarOutlined
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { device } from '../style/breakpoints/device'
import { useAuth } from '../context/AuthProvider'
import { useCompany } from '../context/CompanyProvider'
import { hasAccessToRoute, READ_ONLY_ROLES } from '../utils/RoleUtils'

const { Sider } = Layout

const getOpenKeys = (menuItems, currentPath) => {
  let defaultOpenKey
  let selectedKey
  menuItems.some(item => {
    if (item.label.props && item.label.props.to === currentPath) {
      selectedKey = item.key
      return true
    } else {
      if (item.children) {
        return item.children.some(child => {
          if (child.label.props && child.label.props.to === currentPath) {
            defaultOpenKey = item.key
            selectedKey = child.key
            return true
          }
        })
      }
    }
    return false
  })
  return { openKey: defaultOpenKey, selectedKey: selectedKey }
}

export const SideMenu = props => {
  const company = useCompany();
  const { onCollapse, menuCollapsed } = props
  const [collapsed, setCollapsed] = useState(menuCollapsed)
  const [staticticsLink, setStaticticsLink] = React.useState(company.data && company.data.statisticsUrl ? company.data.statisticsUrl : '')
  const [isDashboardVisible, setDashboardVisibility] = useState({ value: company.data && company.data.isDashboardVisible ? !!company.data.isDashboardVisible : undefined, updated: false });
  const { t } = useTranslation()
  const auth = useAuth()

  const handleCollapse = value => {
    if (onCollapse) {
      onCollapse(value)
    }
    setCollapsed(value)
  }
  useEffect(() => {
    setCollapsed(menuCollapsed)
  }, [menuCollapsed])

  useEffect(() => {
    if(company.data && company.data.statisticsUrl && !staticticsLink) {
      setStaticticsLink(company.data.statisticsUrl)
    }
    if(isDashboardVisible.value !== undefined) {
      return
    }
    if(company.data && company.data.isDashboardVisible !== undefined) {
      setDashboardVisibility({ value: !!company.data.isDashboardVisible, updated: true })
    }
  }, [company.data])
  let menuItems = [
    ...(isDashboardVisible.value === true ? [{
      key: '1',
      label: <Link to='/dashboard'>{t('navigation.dashboard')}</Link>,
      icon: <PieChartOutlined />
    }] : []),
    {
      key: '2',
      label: t('navigation.orders'),
      icon: <SnippetsOutlined />,
      children: [
        ...hasAccessToRoute('/orders/create', auth) ? [{
          key: '20',
          label: (
            <Link to='/orders/create'>{t('navigation.createOrder')}</Link>
          )
        }] : [],
        ...hasAccessToRoute('/orders', auth) ? [{
          key: '22',
          label: (
            <Link to='/orders'>{t('navigation.manageOrders')}</Link>
          )
        }] : [],
      ]
    },
    ...hasAccessToRoute('/reservations/create', auth) ? [{
      key: '3',
      label: t('navigation.reservation'),
      icon: <CalendarOutlined />,
      children: [
        ...hasAccessToRoute('/reservations/create', auth) ? [{
          key: '25',
          label: (
            <Link to='/reservations/create'>{t('navigation.createReservation')}</Link>
          )
        }] : [],
        ...hasAccessToRoute('/reservations', auth) ? [{
          key: '26',
          label: (
            <Link to='/reservations'>{t('navigation.manageReservation')}</Link>
          )
        }] : [],
      ]
    }]: [],
    {
      key: '4',
      label: t('navigation.masterData'),
      icon: <AuditOutlined />,
      children: [
        ...hasAccessToRoute('/items', auth) ? [{
          key: '30',
          label: <Link to='/items'>{t('navigation.items')}</Link>
        }] : [],
        ...hasAccessToRoute('/items/create', auth) ? [{
          key: '31',
          label: <Link to='/items/create'>{t('navigation.addItem')}</Link>
        }] : [],
        ...hasAccessToRoute('/addresses', auth) ? [{
          key: '32',
          label: <Link to='/addresses'>{t('navigation.addresses')}</Link>
        }] : [],
        ...hasAccessToRoute('/groups', auth) ? [{
          key: '33',
          label: <Link to='/groups'>{t('navigation.groups')}</Link>
        }] : [],
        ...hasAccessToRoute('/manufacturers', auth) ? [{
          key: '34',
          label: (
            <Link to='/manufacturers'>{t('navigation.manufacturers')}</Link>
          )
        }] : [],
        ...hasAccessToRoute('/categories', auth) ? [{
          key: '35',
          label: <Link to='/categories'>{t('navigation.categories')}</Link>
        }] : []
        
      ]
    },
    ...hasAccessToRoute('/users', auth) ? [{
      key: '5',
      label: <Link to='/users'>{t('navigation.users')}</Link>,
      icon: <UserOutlined />
    }] : [],
    ...(!!staticticsLink && (auth?.token?.role && !READ_ONLY_ROLES.includes(auth.token.role)) ? [{
      key: '6',
      label: <a target='_blank'
            rel='noopener noreferrer' href={staticticsLink}>{t('navigation.statistics')}</a>,
      icon: <BarChartOutlined style={{ color: "rgba(0,0,0,.85)" }} />
    }] : []),
    ...hasAccessToRoute('/clients', auth) ? [{
      key: '7',
      label: <Link to='/clients'>{t('navigation.clients')}</Link>,
      icon: <TeamOutlined />
    }] : [],
  ]
  menuItems = menuItems.filter(item => !item.children || item.children.length > 0)

  const location = useLocation()
  // Get opened keys first to skip animation
  const key = getOpenKeys(menuItems, location.pathname).openKey
  const [openKeys, setOpenKeys] = useState(key ? [
    key] : [])
  const [selectedKey, setSelectedKey] = useState()


  useEffect(() => {
    if (!collapsed) {
      // Changed opened submenus and selected item on path change
      const keys = getOpenKeys(menuItems, location.pathname)
      if (openKeys && keys.openKey && !openKeys.includes(keys.openKey)) {
        setOpenKeys([...openKeys, keys.openKey])
      }
      setSelectedKey(keys.selectedKey || '1')
    } else {
      setSelectedKey()
      setOpenKeys([])
    }
  }, [location.pathname, collapsed])

  return (
    <StyledSider
      collapsedWidth={0}
      breakpoint='lg'
      onCollapse={handleCollapse}
      collapsed={collapsed}
    >
      <StyledMenu
        mode='inline'
        items={menuItems}
        collapsed={collapsed.toString()}
        selectedKeys={!collapsed && [selectedKey]}
        onOpenChange={(key) => setOpenKeys(key)}
        openKeys={!collapsed && openKeys}
      />
    </StyledSider>
  )
}

const StyledSider = styled(Sider)`
  margin: 10px;
  background-color: white !important;
  z-index: 1000;
  position: absolute;
  @media ${device.laptop} {
    background-color: transparent !important;
    position: relative;
    z-index: 0;
  }
`

const StyledMenu = styled(Menu)`
  padding-right: ${props => props.collapsed === 'false' && '1px'};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  :hover {
    background-color: transparent !important;
  }
`
